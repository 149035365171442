
  import {defineComponent, inject, ref} from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import dateFormat from "@/utils/dateFormat";
import { calendarFormat } from "@/utils/dateFormat";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import verificationCode from "@/components/common/verificationCode.vue";
  import router from "@/router";
// import Modal from "@/components/UI/Modal";
interface CustomObject {
  [key: string]: any;
}
export default defineComponent({
  name: "NeiOrderDetail",
  components: {
    Loading,
    // verificationCode
  },
  setup() {
    const orders = ref<CustomObject | null>(null);
    const details = ref([]);
    const saleOrderId = ref([]);
    const hasSaleOrder = ref([]);
    const returnMsg = ref(false);
    const orderpost = ref<CustomObject[]>([]);
    const route = useRoute();
    const id = route.query.id;
    const loading = ref(true);
    const topicList = ref(null);
    const expendSwitch = ref([true, true, false]);
    const showVerification = ref(false)
    const userInfo = inject("userInfo") as any;
    const ono = ref();
    const postId = ref();
    axios
      .get("/M/User/NeiOrderDetail", {
        params: {
          ONO: id,
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          orders.value = res.data.obj.orders;
          ono.value =res.data.obj.orders.ono;
          postId.value =res.data.obj.orders.postid;
          details.value = res.data.obj.Details;
          hasSaleOrder.value = res.data.obj.hasSaleOrder;
          saleOrderId.value = res.data.obj.saleOrderId;
          topicList.value = res.data.obj.orderTopicList;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
        loading.value = false;
      })
      .catch((e) => {
        Toast({
          type: "error",
          title: e.message || e,
        });
        loading.value = false;
      });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    function onViewWuliu(index: any) {
      orderpost.value[index].kd = orderpost.value[index].traces
       // console.log(orderpost.value, '9999999999999999999')
    }
    function switchExpend(index: number, value: boolean) {
      expendSwitch.value[index] = !value;
    }
    function hasSaleOrderTip() {
      router.push({
        name: "SaleOrderDetail",
        query: {
          id: saleOrderId.value,
        },
      });
    }
    function confirmSub(code: any) {
      // if (!code.value) {
      //   Toast({
      //     title: '请输入验证码！',
      //     type: "error",
      //   });
      //   return false
      // }

      const formData = new FormData();
      formData.append("ono", (orders.value as any).ono);
      formData.append("code", code.value);
      axios.post("/M/User/neiOrderExpressTrace", formData)
        .then((res) => {
          if (res.data.success) {
            // showVerification.value = false
            orderpost.value = res.data.obj
            if(res.data.obj&&res.data.obj.length<1){
              returnMsg.value =true;
            }
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });

    }

    function toUpdateOrderAddress() {
        // console.log("postId.value",postId.value)
        // console.log("ono.value",ono.value)
        if(postId.value && ono.value){
            router.push({
                name: "updateAddressOfOrder",
                query: {
                    postId: postId.value,
                    ono: ono.value,
                },
            });
        }else{
              Toast({
                title: '信息不全，无法修改',
                type: "error",
              });
              return false
        }

    }
    return {
      orders,
      dateFormat,
      details,
      orderpost,
      loading,
      loadOption,
      topicList,
      saleOrderId,
      hasSaleOrder,
        returnMsg,
      onViewWuliu,
      expendSwitch,
      switchExpend,
      hasSaleOrderTip,
      calendarFormat,
      // viewDetail,
      // showVerification,
      // onClose,
      confirmSub,
      toUpdateOrderAddress,
      userInfo,
      ono,
      postId,
    };
  },
});
